import './App.css';

import { BrowserRouter } from 'react-router-dom';

import { ModalLayout } from './components/ModalController/ModalsLayout';
import { AuthContextProvider, ModalContextProvider } from './context';
import { RouterWrapper } from './router/Router';

const App = () => {
  return (
    <BrowserRouter>
      <ModalContextProvider>
        <ModalLayout>
          <AuthContextProvider>
            <RouterWrapper />
          </AuthContextProvider>
        </ModalLayout>
      </ModalContextProvider>
    </BrowserRouter>
  );
};

export default App;
