import { Avatar, Box, Link as MuiLink } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { UserService } from '../../services/User.service';
import palette from '../../theme/palette';
import { createPathToUploadFiles } from '../../utils/createPathToUploadFiles';

const columns = [
  {
    field: 'id',
    headerName: 'ID',
    width: 90,
  },
  {
    field: 'avatarPath',
    headerName: 'Avatar',
    disableColumnMenu: true,
    sortable: false,
    width: 70,
    renderCell: (params) => {
      return (
        <Link
          style={{
            color: palette.primary,
            textDecoration: 'none',
          }}
          to={`/user/${params.row.id}`}
        >
          <Avatar
            sx={{
              width: 40,
              height: 40,
              margin: '0 auto',
            }}
            src={createPathToUploadFiles(params.row.avatarPath)}
            variant='square'
          />
        </Link>
      );
    },
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 150,
    renderCell: (params) => {
      return (
        <Link
          style={{
            color: palette.primary,
            textDecoration: 'none',
          }}
          to={`/user/${params.row.id}`}
        >
          {`${params.row.name || ''}`}
        </Link>
      );
    },
  },
  {
    field: 'surname',
    headerName: 'Surname',
    width: 150,
    renderCell: (params) => {
      return (
        <Link
          style={{
            color: palette.primary,
            textDecoration: 'none',
          }}
          to={`/user/${params.row.id}`}
        >
          {`${params.row.surname || ''}`}
        </Link>
      );
    },
  },
  {
    field: 'confirmationStatus',
    headerName: 'Status',
    width: 100,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 200,
    renderCell: (params) => {
      return (
        <MuiLink color={palette.primary} underline={'none'} href={`mailto:${params.row.email}`}>
          {params.row.email}
        </MuiLink>
      );
    },
  },
  {
    field: 'phone',
    headerName: 'Phone',
    width: 200,
    renderCell: (params) => {
      return (
        <MuiLink color={palette.primary} underline={'none'} href={`tel:${params.row.phone}`}>
          {params.row.phone}
        </MuiLink>
      );
    },
  },
  {
    field: 'location',
    headerName: 'Location',
    width: 250,
  },
];

export const Home = () => {
  const [users, setUsers] = useState();
  useEffect(() => {
    const fetchData = async () => {
      await UserService.getUsers().then((data) => setUsers(data?.users));
    };

    fetchData();
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        margin: '0 0 20px',
      }}
    >
      <DataGrid
        rows={users || []}
        columns={columns}
        disableSelectionOnClick
        autoPageSize={true}
        width={true}
        sx={{
          color: palette.primary,
        }}
        experimentalFeatures={{
          newEditingApi: true,
        }}
      />
    </Box>
  );
};
