import { useRoutes } from 'react-router-dom';

import { AuthGuard, Layout, NoAuthGuard } from '../components';
import { ROUTES } from '../constants';
// eslint-disable-next-line object-curly-newline
import { Guide, Guides, Home, Login, NotFound, Reviews, Tour, Tours, User } from '../pages';

const routes = [
  {
    path: ROUTES.home,
    element: (
      <AuthGuard>
        <Home />
      </AuthGuard>
    ),
  },
  {
    path: ROUTES.user,
    element: (
      <AuthGuard>
        <User />
      </AuthGuard>
    ),
  },
  {
    path: ROUTES.guides,
    element: (
      <AuthGuard>
        <Guides />
      </AuthGuard>
    ),
  },
  {
    path: ROUTES.guide,
    element: (
      <AuthGuard>
        <Guide />
      </AuthGuard>
    ),
  },
  {
    path: ROUTES.tours,
    element: (
      <AuthGuard>
        <Tours />
      </AuthGuard>
    ),
  },
  {
    path: ROUTES.tour,
    element: (
      <AuthGuard>
        <Tour />
      </AuthGuard>
    ),
  },
  {
    path: ROUTES.reviews,
    element: (
      <AuthGuard>
        <Reviews />
      </AuthGuard>
    ),
  },
  {
    path: ROUTES.login,
    element: (
      <NoAuthGuard>
        <Login />
      </NoAuthGuard>
    ),
  },
  {
    path: '*',
    element: <NotFound />,
  },
];

export const RouterWrapper = () => {
  return <Layout>{useRoutes(routes)}</Layout>;
};
