import { styled } from '@mui/material';

import palette from '../../theme/palette';

export const StyledForm = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  '.MuiBox-root': {
    marginBottom: '25px',
  },
  '.MuiFormHelperText-root': {
    position: 'absolute',
    bottom: '-20px',
    color: palette.red,
  },
});
