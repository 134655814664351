import EditLocationIcon from '@mui/icons-material/EditLocation';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { Avatar, Box, Card, Chip, Link, Rating, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { GuideService } from '../../services/Guide.service';
import palette from '../../theme/palette';
import { createPathToUploadFiles } from '../../utils/createPathToUploadFiles';
import {
  Contacts,
  Description,
  GuidePage,
  GuidePageTopLine,
  Info,
  Location,
  Status,
  StyledTitle,
} from './styles';

export const Guide = () => {
  const [guide, setGuide] = useState();
  const { id } = useParams();
  const name = `${guide?.name || ''} ${guide?.surname || ''}`;

  useEffect(() => {
    const fetchData = async () => {
      await GuideService.getGuide(id).then((data) => setGuide(data));
    };
    fetchData();
  }, []);

  return (
    <GuidePage>
      {guide && (
        <Card
          sx={{
            maxWidth: '100%',
            background: palette.lightGray,
            margin: '0 auto',
          }}
        >
          <GuidePageTopLine>
            <Avatar
              src={createPathToUploadFiles(guide.avatarPath)}
              variant={'square'}
              sx={{
                width: '215px',
                height: '215px',
              }}
            />
            <Info>
              <Box>
                <StyledTitle>
                  <Typography variant={'h4'}>
                    {name.length > 1 ? name : 'Name not provided'}
                  </Typography>
                  <Location component={'div'} display={'flex'} alignItems={'center'}>
                    <EditLocationIcon
                      sx={{
                        fill: palette.primary,
                      }}
                    />
                    {guide.location || 'Location not provided'}
                  </Location>
                </StyledTitle>
                <Rating
                  name='half-rating'
                  defaultValue={guide.guideFields?.rating || 0}
                  precision={0.1}
                  readOnly
                />
                <Status>User status - {guide.confirmationStatus}</Status>
                <Status>Role - {guide.role}</Status>
                <Status>Points - {guide.guideFields.points || 0}</Status>
                <Contacts>
                  <Link href={`tel:${guide.phone}`} underline={'none'}>
                    <Chip
                      icon={
                        <LocalPhoneIcon
                          sx={{
                            fill: palette.white,
                          }}
                        />
                      }
                      label={guide.phone || 'Phone not provided'}
                    />
                  </Link>
                  <Link href={`mailto:${guide.email}`} underline={'none'}>
                    <Chip
                      icon={
                        <EmailIcon
                          sx={{
                            fill: palette.white,
                          }}
                        />
                      }
                      label={guide.email || 'Email not provided'}
                    />
                  </Link>
                </Contacts>
              </Box>
            </Info>
          </GuidePageTopLine>
          {guide.guideFields?.description && (
            <Description>{guide.guideFields?.description}</Description>
          )}
        </Card>
      )}
    </GuidePage>
  );
};
