import { Box, IconButton, Modal, styled } from '@mui/material';

import palette from '../../../theme/palette';

export const StyledModal = styled(Modal)({
  position: 'fixed',
  zIndex: 1300,
  '.MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    position: 'fixed',
    right: 0,
    left: 0,
    bottom: 0,
    top: 0,
    zIndex: -1,
  },
});

export const StyleModalContent = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  background: palette.white,
  minWidth: '360px',
  maxWidth: '560px',
  maxHeight: '90vh',
  overflow: 'auto',
  padding: '15px',
  boxSizing: 'border-box',
  '> div': {
    margin: '10px 0 0',
    '> p:first-of-type': {
      display: 'inline-block',
      marginRight: '20px',
    },
  },
});

export const StyledList = styled('ul')({
  margin: 0,
  padding: 0,
  columnCount: 2,
  listStyleType: 'none',
});

export const StyledForm = styled('form')({
  marginTop: '10px',
  display: 'flex',
  flexDirection: 'column',
  '.MuiFormControl-root': {
    marginBottom: '10px',
  },
});

export const StyledIconButton = styled(IconButton)({
  position: 'absolute',
  right: '5px',
  top: '5px',
});
