import { Button, styled } from '@mui/material';

import palette from '../../../theme/palette';

export const StyledButton = styled(Button)({
  minWidth: '200px',
  height: '40px',
  '&.primary': {
    background: palette.secondary,
    color: palette.white,
  },
  '&.center': {
    margin: '0 auto',
  },
});
