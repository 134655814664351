const palette = {
  primary: '#114656',
  secondary: '#2295EF',
  green: 'green',
  red: '#F70000',
  white: '#fff',
  lightGray: '#f7f7f7',
};

export default palette;
