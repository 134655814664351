import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

import { ROUTES } from '../../constants';
import { useAuthContext } from '../../context';

export const AuthGuard = ({ children }) => {
  const { isAuth } = useAuthContext();

  if (!isAuth) {
    return <Navigate to={ROUTES.login} replace />;
  }

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};
