import { useModalContext } from '../../../context';

export const TestModal = () => {
  const { modalParameters } = useModalContext();

  return (
    <div>
      <div>{modalParameters?.test_value}</div>
      <button
        onClick={() => {
          modalParameters?.onCallBack();
        }}
      >
        action
      </button>
    </div>
  );
};
