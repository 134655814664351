import { styled } from '@mui/material';
import { NavLink } from 'react-router-dom';

import palette from '../../theme/palette';

export const Container = styled('div')({
  padding: '0 30px',
  margin: '0 auto',
  width: '100%',
  display: 'flex',
  boxSizing: 'border-box',
  height: 'calc(100vh - 115px)',
});

export const StyledMenu = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const StyledNavLink = styled(NavLink)({
  textDecoration: 'none',
  color: palette.primary,
  padding: '0 20px',
  '&.active': {
    color: palette.secondary,
  },
});
