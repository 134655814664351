import { Box, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

export const Input = ({
  label,
  nativeLabel,
  placeholder,
  defaultValue,
  disabled,
  field,
  name,
  type,
  errors,
}) => {
  return (
    <Box>
      {!nativeLabel && label ? (
        <label>
          <Typography>{label}</Typography>
        </label>
      ) : null}
      <TextField
        name={name}
        error={!!errors}
        helperText={errors || ''}
        placeholder={placeholder}
        type={type || 'text'}
        fullWidth
        defaultValue={defaultValue}
        disabled={disabled}
        {...field}
      />
    </Box>
  );
};

Input.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  nativeLabel: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  field: PropTypes.object,
  errors: PropTypes.string,
};

export const ControlInput = ({ control, name, errors, ...props }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <Input field={field} errors={errors} {...props} />}
    />
  );
};

ControlInput.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
  errors: PropTypes.string,
};
