import { Box, styled, Typography } from '@mui/material';

import theme from '../../theme';
import palette from '../../theme/palette';

export const UserPage = styled(Box)({
  color: palette.primary,
  width: '100%',
});

export const UserPageTopLine = styled(Box)({
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
});

export const Info = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '15px 15px 0 15px',
  [theme.breakpoints.down('md')]: {
    alignItems: 'center',
    '> div:first-of-type': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
});

export const Contacts = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  '.MuiLink-root': {
    margin: '10px 20px 10px 0',
  },
  '.MuiChip-root': {
    backgroundColor: palette.secondary,
    color: palette.white,
    cursor: 'pointer',
    padding: '5px',
  },
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
    '.MuiLink-root': {
      margin: '10px 10px 0',
    },
  },
});

export const Status = styled(Typography)({});

export const Location = styled(Typography)({
  marginTop: '5px',
});

export const StyledTitle = styled(Box)({
  display: 'flex',
  h4: {
    marginRight: '20px',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'center',
    h4: {
      margin: 0,
    },
  },
});
