import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

import { ROUTES } from '../../constants';
import { useAuthContext } from '../../context';

export const NoAuthGuard = ({ children }) => {
  const { isAuth } = useAuthContext();

  if (isAuth) {
    return <Navigate to={ROUTES.home} replace />;
  }

  return children;
};

NoAuthGuard.propTypes = {
  children: PropTypes.node,
};
