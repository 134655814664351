import instance from '../api';

export const ToursService = {
  async getTours() {
    return instance.get('/admin/tour').then((response) => response.data);
  },
  async getTour(id) {
    return instance.get(`/admin/tour/${id}`).then((response) => response.data);
  },
  async changeTourStatus(status, tourContentId) {
    return instance
      .patch(`/admin/tour/content/status`, {
        tourContentId,
        status,
      })
      .then((response) => response.data);
  },
};
