import instance from '../api';

export const ReviewsService = {
  async getReviews(statusParam) {
    const status = statusParam ? `?status=${statusParam}` : '';
    return instance.get(`admin/review${status}`).then((response) => response.data);
  },
  async getReview(id) {
    return instance.get(`/admin/review/${id}`).then((response) => response.data);
  },
  async changeReviewStatus(reviewId, status, reason) {
    return instance
      .patch('/admin/review', {
        reviewId,
        status,
        reason,
      })
      .then((response) => response.data);
  },
};
