import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { Box, styled, Tab, Tabs, useMediaQuery } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';

import { ModerateReviewModal } from '../../components/Modal';
import { REVIEWS_STATUSES } from '../../constants';
import { ReviewsService } from '../../services/Reviews.service';
import theme from '../../theme';
import palette from '../../theme/palette';

const columns = [
  {
    field: 'id',
    headerName: 'ID',
    width: 90,
  },
  {
    field: 'tourId',
    headerName: 'Tour ID',
    width: 90,
  },
  {
    field: 'userId',
    headerName: 'User ID',
    width: 90,
  },
  {
    field: 'title',
    headerName: 'Title',
    width: 180,
  },
  {
    field: 'text',
    headerName: 'Text',
    width: 240,
  },
  {
    field: 'overall',
    headerName: 'Overall',
    valueGetter: (params) => `${params?.row?.ratingValue?.overall}`,
    width: 120,
    renderCell: (params) => {
      return (
        <Box
          display={'flex'}
          alignItems={'center'}
          fontSize={'20px'}
          justifyContent={'flex-end'}
          width={'52px'}
        >
          {params?.row?.ratingValue?.overall}
          <StarIcon />
        </Box>
      );
    },
  },
  {
    field: 'program',
    headerName: 'Program',
    valueGetter: (params) => `${params?.row?.ratingValue?.program}`,
    width: 120,
    renderCell: (params) => {
      return (
        <Box
          display={'flex'}
          alignItems={'center'}
          fontSize={'20px'}
          justifyContent={'flex-end'}
          width={'52px'}
        >
          {params?.row?.ratingValue?.program}
          <StarBorderIcon />
        </Box>
      );
    },
  },
  {
    field: 'emotions',
    headerName: 'Emotions',
    valueGetter: (params) => `${params?.row?.ratingValue?.emotions}`,
    width: 120,
    renderCell: (params) => {
      return (
        <Box
          display={'flex'}
          alignItems={'center'}
          fontSize={'20px'}
          justifyContent={'flex-end'}
          width={'52px'}
        >
          {params?.row?.ratingValue?.emotions}
          <StarBorderIcon />
        </Box>
      );
    },
  },
  {
    field: 'extreme',
    headerName: 'Extreme',
    valueGetter: (params) => `${params?.row?.ratingValue?.extreme}`,
    width: 120,
    renderCell: (params) => {
      return (
        <Box
          display={'flex'}
          alignItems={'center'}
          fontSize={'20px'}
          justifyContent={'flex-end'}
          width={'52px'}
        >
          {params?.row?.ratingValue?.extreme}
          <StarBorderIcon />
        </Box>
      );
    },
  },
  {
    field: 'impressions',
    headerName: 'Impressions',
    valueGetter: (params) => `${params?.row?.ratingValue?.impressions}`,
    width: 120,
    renderCell: (params) => {
      return (
        <Box
          display={'flex'}
          alignItems={'center'}
          fontSize={'20px'}
          justifyContent={'flex-end'}
          width={'52px'}
        >
          {params?.row?.ratingValue?.impressions}
          <StarBorderIcon />
        </Box>
      );
    },
  },
  {
    field: 'expectations',
    headerName: 'Expectations',
    valueGetter: (params) => `${params?.row?.ratingValue?.expectations}`,
    width: 120,
    renderCell: (params) => {
      return (
        <Box
          display={'flex'}
          alignItems={'center'}
          fontSize={'20px'}
          justifyContent={'flex-end'}
          width={'52px'}
        >
          {params?.row?.ratingValue?.expectations}
          <StarBorderIcon />
        </Box>
      );
    },
  },
  {
    field: 'recommend',
    headerName: 'Recommend',
    valueGetter: (params) => `${params?.row?.ratingValue?.recommend}`,
    width: 120,
    renderCell: (params) => {
      return (
        <Box
          display={'flex'}
          alignItems={'center'}
          fontSize={'20px'}
          justifyContent={'flex-end'}
          width={'52px'}
        >
          {params?.row?.ratingValue?.recommend}
          <StarBorderIcon />
        </Box>
      );
    },
  },
  {
    field: 'value',
    headerName: 'Value',
    valueGetter: (params) => `${params?.row?.ratingValue?.value}`,
    width: 120,
    renderCell: (params) => {
      return (
        <Box
          display={'flex'}
          alignItems={'center'}
          fontSize={'20px'}
          justifyContent={'flex-end'}
          width={'52px'}
        >
          {params?.row?.ratingValue?.value}
          <StarBorderIcon />
        </Box>
      );
    },
  },
];

const StyledTabs = styled(Tabs)({
  root: {
    '& .MuiTabs-indicator': {
      backgroundColor: '#2295EF',
    },
  },
});

export const Reviews = () => {
  const [reviews, setReviews] = useState();
  const [reviewsStatus, setReviewsStatus] = useState('');
  const [selectedReview, setSelectedReview] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    const fetchData = async (reviewsStatus) => {
      await ReviewsService.getReviews(reviewsStatus).then((data) => {
        setReviews(data?.reviews);
        setRefetch(false);
      });
    };

    fetchData(reviewsStatus);
  }, [reviewsStatus, refetch]);

  const handleChange = (event, newValue) => {
    setReviewsStatus(newValue);
  };

  const openModal = (params) => {
    const fetchReviewData = async (params) => {
      await ReviewsService.getReview(params).then((data) => {
        setSelectedReview(data);
        setIsModalOpen(true);
      });
    };

    fetchReviewData(params?.row?.id);
  };

  return (
    <Box
      sx={{
        width: '100%',
        margin: '0 0 20px',
      }}
    >
      <StyledTabs
        value={reviewsStatus}
        onChange={handleChange}
        textColor='inherit'
        variant={isMobile ? 'scrollable' : 'fullWidth'}
      >
        {REVIEWS_STATUSES.map(({ id, status, title }) => (
          <Tab key={id} label={title} value={status} />
        ))}
      </StyledTabs>
      <DataGrid
        rows={reviews || []}
        columns={columns}
        disableSelectionOnClick
        onRowClick={openModal}
        autoPageSize={true}
        width={true}
        sx={{
          color: palette.primary,
        }}
        experimentalFeatures={{
          newEditingApi: true,
        }}
      />
      <ModerateReviewModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        data={selectedReview}
        setRefetch={setRefetch}
      />
    </Box>
  );
};
