import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';

import { SELECT_OPTIONS } from '../../../constants';
import { ReviewsService } from '../../../services/Reviews.service';
import { manageReviewSchema } from '../../../validation';
import { Button } from '../../UI/Button/Button';
import { StyledForm, StyledIconButton, StyledList, StyledModal, StyleModalContent } from './styles';

const LIST_DATA = (data) => [
  {
    id: 0,
    name: 'Emotions',
    value: data?.emotions,
  },
  {
    id: 1,
    name: 'Expectations',
    value: data?.expectations,
  },
  {
    id: 2,
    name: 'Extreme',
    value: data?.extreme,
  },
  {
    id: 3,
    name: 'Impressions',
    value: data?.impressions,
  },
  {
    id: 4,
    name: 'Value',
    value: data?.value,
  },
  {
    id: 5,
    name: 'Program',
    value: data?.program,
  },
  {
    id: 6,
    name: 'Recommend',
    value: data?.recommend,
  },
  {
    id: 7,
    name: 'Overall',
    value: data?.overall,
  },
];

export const ModerateReviewModal = ({ open, setOpen, data, setRefetch }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(manageReviewSchema),
  });

  const handleClose = () => setOpen(false);

  const onSubmit = async ({ status, reason }) => {
    await ReviewsService.changeReviewStatus(data?.id, status, reason).then(() => setRefetch(true));
    handleClose();
  };
  return (
    <StyledModal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <StyleModalContent>
        <StyledIconButton onClick={handleClose}>
          <CloseIcon />
        </StyledIconButton>
        <Typography textAlign={'center'} variant={'h5'}>
          Manage review
        </Typography>
        <Box display={'flex'}>
          <Typography>
            <strong>Tour ID </strong>- {data.tourId}
          </Typography>
          <Typography>
            <strong>Title</strong> - {data.title}
          </Typography>
        </Box>
        <Typography>
          <strong>Text</strong> - {data.text}
        </Typography>
        <StyledList>
          {LIST_DATA(data?.ratingValue).map(({ id, name, value }) => {
            return (
              <li key={id}>
                <span>
                  <strong>{name}</strong> -{' '}
                </span>
                <span>{value}</span>
              </li>
            );
          })}
        </StyledList>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <FormControl variant={'standard'} fullWidth error={Boolean(errors.status)}>
            <InputLabel id='status-label'>Status *</InputLabel>
            <Select
              labelId='status-label'
              id='status'
              name='status'
              {...register('status')}
              defaultValue={''}
            >
              {SELECT_OPTIONS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            {errors.status && <p>{errors.status.message}</p>}
          </FormControl>
          <TextField
            id='reason'
            name='reason'
            label='Reason'
            multiline
            rows={4}
            {...register('reason')}
          />
          <Button className={'primary'} type='submit'>
            Submit
          </Button>
        </StyledForm>
      </StyleModalContent>
    </StyledModal>
  );
};
