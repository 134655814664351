import EditLocationIcon from '@mui/icons-material/EditLocation';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import {
  Alert,
  Avatar,
  Box,
  Chip,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  Tab,
  TableBody,
  TableCell,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button } from '../../components';
import { TOUR_STATUSES } from '../../constants';
import { ToursService } from '../../services/Tours.service';
import palette from '../../theme/palette';
import { createPathToUploadFiles } from '../../utils/createPathToUploadFiles';
import {
  Contacts,
  CreatorCard,
  Info,
  Location,
  Status,
  StyledChip,
  StyledTable,
  StyledTabs,
  StyledTitle,
  TourCard,
  TourCardTopLine,
  TourPage,
} from './styles';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role={'tabpanel'}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={'tab'}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            padding: '10px 10px 20px 0',
          }}
        >
          <Typography component={'div'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export const Tour = () => {
  const [tour, setTour] = useState();
  const [value, setValue] = useState(0);
  const [tourStatus, setTourStatus] = useState();
  const [isToast, setIsToast] = useState(false);
  const [toastType, setToastType] = useState('success');
  const { id } = useParams();

  const handleClick = (type) => {
    setToastType(type);
    setIsToast(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsToast(false);
  };

  useEffect(() => {
    if (tour) setTourStatus(tour?.contents[value]?.status);
  }, [tour, value]);

  useEffect(() => {
    const fetchData = async () => {
      await ToursService.getTour(id).then((data) => setTour(data));
    };

    fetchData();
  }, []);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange = (event) => {
    setTourStatus(event.target.value);
  };

  const changeStatus = async () => {
    await ToursService.changeTourStatus(tourStatus, tour?.contents[value]?.id)
      .then(() => {
        handleClick('success');
        ToursService.getTour(id).then((data) => setTour(data));
      })
      .catch(() => handleClick('error'));
  };

  return (
    <TourPage>
      {tour && (
        <>
          <TourCard>
            <TourCardTopLine>
              <Box>
                <Avatar
                  src={createPathToUploadFiles(tour.picturePath)}
                  variant={'square'}
                  sx={{
                    width: '215px',
                    height: '215px',
                  }}
                />
                <Status>
                  <FormControl>
                    <InputLabel id='demo-simple-select-label'>Status</InputLabel>
                    <Select
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      value={tourStatus || tour?.contents[value]?.status}
                      label='Status'
                      onChange={handleChange}
                    >
                      {TOUR_STATUSES.map(({ id, status, value }) => (
                        <MenuItem key={id} value={status}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                    <Button className={'primary center'} onClick={changeStatus}>
                      Save
                    </Button>
                  </FormControl>
                </Status>
              </Box>
              <Info>
                <Box>
                  <StyledTitle>
                    <Typography variant={'h4'}>
                      {tour?.contents?.title || 'Name not provided'}
                    </Typography>
                    <Stack direction={'row'}>
                      {tour?.tags?.map(({ name, id }) => (
                        <StyledChip key={id} label={name} />
                      ))}
                    </Stack>
                  </StyledTitle>
                  <Typography>
                    Duration - <strong>{tour?.duration}</strong>
                  </Typography>
                  <Typography>
                    Price - <strong>{`${tour?.price} ${tour?.currencyCode}`}</strong>
                  </Typography>
                  <StyledTable>
                    <TableRow>
                      <TableCell>Purchased</TableCell>
                      <TableCell>Liked</TableCell>
                      <TableCell>In Progress</TableCell>
                      <TableCell>Ended</TableCell>
                    </TableRow>
                    <TableBody>
                      <TableRow>
                        <TableCell>{tour.statistics?.purchased}</TableCell>
                        <TableCell>{tour.statistics?.liked}</TableCell>
                        <TableCell>{tour.statistics?.inProgress}</TableCell>
                        <TableCell>{tour.statistics?.ended}</TableCell>
                      </TableRow>
                    </TableBody>
                  </StyledTable>
                </Box>
                <StyledTabs
                  sx={{
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: 'divider',
                    }}
                  >
                    <Tabs
                      variant='scrollable'
                      scrollButtons='auto'
                      value={value}
                      onChange={handleChangeTab}
                    >
                      {tour.contents?.map((item, i) => (
                        <Tab
                          key={item?.id}
                          label={
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                lineHeight: '16px',
                              }}
                            >
                              <span>{item?.title}</span>
                              <img
                                alt={item?.title}
                                src={createPathToUploadFiles(item?.language?.icon)}
                                style={{
                                  width: '30px',
                                  height: '15px',
                                  marginLeft: '5px',
                                }}
                              />
                            </div>
                          }
                          {...a11yProps(i)}
                        />
                      ))}
                    </Tabs>
                  </Box>
                  {tour.contents.map(
                    ({ about, chapters, city, includes, title, description, id }, index) => {
                      return (
                        <TabPanel key={id} value={value} index={index}>
                          <StyledTitle>
                            <Typography variant={'h4'}>{title}</Typography>
                            <Location component={'div'} display={'flex'} alignItems={'center'}>
                              <EditLocationIcon
                                sx={{
                                  fill: palette.primary,
                                }}
                              />
                              {city?.name || 'Location not provided'}
                            </Location>
                          </StyledTitle>
                          <Typography variant={'body2'} fontSize={'14px'}>
                            <strong>Description - </strong>
                            {description}
                          </Typography>
                          <Typography variant={'body1'} fontSize={'14px'}>
                            <strong>Includes - </strong>
                            {includes}
                          </Typography>
                          <Typography variant={'body2'}>
                            <strong>About - </strong>
                            {about}
                          </Typography>
                          {chapters.length > 0 &&
                            chapters.map((item, i) => {
                              return (
                                <Box key={i}>
                                  <Typography margin={'10px 0 0'} variant={'h5'}>
                                    {item?.name}
                                  </Typography>
                                  <Box display={'flex'} flexWrap={'wrap'} alignItems={'center'}>
                                    {item?.imagePaths?.length > 0 &&
                                      item?.imagePaths?.map((path, i) => (
                                        <Avatar
                                          key={i}
                                          src={createPathToUploadFiles(path.name)}
                                          variant={'square'}
                                          sx={{
                                            width: '100px',
                                            height: '100px',
                                            margin: '10px 0',
                                          }}
                                        />
                                      ))}
                                  </Box>
                                  {(item?.route?.coordinates?.latitude ||
                                    item?.route?.coordinates?.longitude) && (
                                    <Typography>
                                      <strong>Coordinates - </strong>{' '}
                                      {`latitude: ${item?.route?.coordinates?.latitude}, longitude: ${item?.route?.coordinates?.longitude}`}
                                    </Typography>
                                  )}
                                  {item?.route?.duration !== 0 && (
                                    <Typography>
                                      <strong>Duration - </strong>
                                      {item?.route?.duration}
                                    </Typography>
                                  )}
                                  {item?.audioPaths.length > 0 &&
                                    item?.audioPaths.map((item, i) => (
                                      <audio
                                        key={i}
                                        src={createPathToUploadFiles(item.name)}
                                        controls
                                      ></audio>
                                    ))}
                                </Box>
                              );
                            })}
                        </TabPanel>
                      );
                    },
                  )}
                </StyledTabs>
              </Info>
            </TourCardTopLine>
          </TourCard>
          <CreatorCard>
            <Avatar
              src={createPathToUploadFiles(tour.creator?.avatarPath)}
              variant={'square'}
              sx={{
                width: '215px',
                height: '215px',
              }}
            />
            <Typography margin={'10px 0'} variant={'h5'}>
              Creator - {`${tour.creator?.name || ''} ${tour.creator?.surname || ''}`}
            </Typography>
            <Contacts>
              <Link href={`tel:${tour.creator?.phone}`} underline={'none'}>
                <Chip
                  icon={
                    <LocalPhoneIcon
                      sx={{
                        fill: palette.white,
                      }}
                    />
                  }
                  label={tour.creator?.phone || 'Phone not provided'}
                />
              </Link>
              <Link href={`mailto:${tour.creator?.email}`} underline={'none'}>
                <Chip
                  icon={
                    <EmailIcon
                      sx={{
                        fill: palette.white,
                      }}
                    />
                  }
                  label={tour.creator?.email || 'Email not provided'}
                />
              </Link>
            </Contacts>
          </CreatorCard>
          <Snackbar
            open={isToast}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <Alert onClose={handleClose} severity={toastType}>
              {toastType === 'success' ? 'Saved' : 'Error. Try again please.'}
            </Alert>
          </Snackbar>
        </>
      )}
    </TourPage>
  );
};
