import axios from 'axios';

import instance from '../api';
import { API_URL } from '../constants';

export const AuthService = {
  async signIn(email, password) {
    return instance
      .post('/auth/sign-in', {
        email,
        password,
        role: 'admin',
      })
      .then((response) => response.data);
  },

  async updateAccessToken(refreshToken) {
    return axios
      .post(`${API_URL}/auth/update-access-token`, {
        refreshToken,
      })
      .then((response) => response.data);
  },
};
