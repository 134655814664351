import axios from 'axios';

import { API_URL } from '../constants';
import { AuthService } from '../services/Auth.service';
import { errorCatch } from './api.helpers';

const instance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    accept: 'application/json',
  },
});

instance.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  return config;
});

instance.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && error.config && !error.config._isRetry) {
      originalRequest._isRetry = true;
      try {
        const response = await AuthService.updateAccessToken(localStorage.getItem('refreshToken'));
        localStorage.setItem('token', response?.accessToken);
        return instance.request(originalRequest);
      } catch (error) {
        if (errorCatch(error) === 'InvalidAccessToken') {
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
        }
      }
    }

    throw error;
  },
);

export default instance;
