import { Avatar, Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { ToursService } from '../../services/Tours.service';
import palette from '../../theme/palette';
import { createPathToUploadFiles } from '../../utils/createPathToUploadFiles';

const columns = [
  {
    field: 'id',
    headerName: 'ID',
    width: 90,
  },
  {
    field: 'picturePath',
    headerName: 'Picture',
    disableColumnMenu: true,
    sortable: false,
    width: 120,
    renderCell: (params) => {
      return (
        <Link
          style={{
            color: palette.primary,
            textDecoration: 'none',
          }}
          to={`/tour/${params.row.id}`}
        >
          <Avatar
            sx={{
              width: 40,
              height: 40,
            }}
            src={createPathToUploadFiles(params.row.picturePath)}
            variant='square'
          />
        </Link>
      );
    },
  },
  {
    field: 'name',
    headerName: 'Name',
    valueGetter: (params) => `${params.row?.contents[0]?.title}`,
    width: 250,
    renderCell: (params) => {
      return (
        <Link
          style={{
            color: palette.primary,
            textDecoration: 'none',
          }}
          to={`/tour/${params.row.id}`}
        >
          {params.row.contents[0]?.title || ''}
        </Link>
      );
    },
  },
  {
    field: 'duration',
    headerName: 'Duration',
    disableColumnMenu: true,
    sortable: false,
    width: 100,
    renderCell: (params) => {
      return <span>{`${params.row?.duration || ''}`}</span>;
    },
  },
  {
    field: 'price',
    headerName: 'Price',
    width: 94,
    renderCell: (params) => {
      return <span>{`${params.row?.price} ${params.row?.currencyCode}`}</span>;
    },
  },
  {
    field: 'status',
    headerName: 'Language - Status',
    width: 364,
    renderCell: (params) => {
      return (
        <ul
          style={{
            margin: 0,
            padding: 0,
            listStyleType: 'none',
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          {params.row?.contents?.map((content) => (
            <li
              style={{
                marginRight: '10px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img
                style={{
                  objectFit: 'cover',
                  height: '10px',
                  width: '15px',
                  marginRight: '5px',
                }}
                alt={content.title}
                src={createPathToUploadFiles(content.language.icon)}
              />
              <span
                style={{
                  fontSize: '13px',
                }}
              >
                {' '}
                - {content.status}
              </span>
            </li>
          ))}
        </ul>
      );
    },
  },
  {
    field: 'creator',
    headerName: 'Creator',
    width: 100,
    valueGetter: (params) =>
      `${params.row?.creator?.name || ''} ${params.row?.creator?.surname || ''}`,
    renderCell: (params) => {
      return (
        <Link
          style={{
            color: palette.primary,
            textDecoration: 'none',
          }}
          color={palette.primary}
          underline={'none'}
          to={`/guide/${params.row.id}`}
        >
          {`${params.row?.creator?.name || ''} ${params.row?.creator?.surname || ''}`}
        </Link>
      );
    },
  },
  {
    field: 'liked',
    headerName: 'Liked',
    disableColumnMenu: true,
    width: 130,
    renderCell: (params) => {
      return <span>{params.row?.statistics?.liked}</span>;
    },
  },
  {
    field: 'inProgress',
    headerName: 'In Progress',
    disableColumnMenu: true,
    width: 130,
    renderCell: (params) => {
      return <span>{params.row?.statistics?.inProgress}</span>;
    },
  },
  {
    field: 'purchased',
    headerName: 'Purchased',
    disableColumnMenu: true,
    width: 130,
    renderCell: (params) => {
      return <span>{params.row?.statistics?.purchased}</span>;
    },
  },
  {
    field: 'ended',
    headerName: 'Ended',
    disableColumnMenu: true,
    width: 130,
    renderCell: (params) => {
      return <span>{params.row?.statistics?.ended}</span>;
    },
  },
];

export const Tours = () => {
  const [tours, setTours] = useState();
  useEffect(() => {
    const fetchData = async () => {
      await ToursService.getTours().then((data) => setTours(data?.tours));
    };

    fetchData();
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        margin: '0 0 20px',
      }}
    >
      <DataGrid
        rows={tours || []}
        columns={columns}
        disableSelectionOnClick
        autoPageSize={true}
        width={true}
        sx={{
          color: palette.primary,
        }}
        experimentalFeatures={{
          newEditingApi: true,
        }}
      />
    </Box>
  );
};
