import { Box, Card, Chip, styled, Table, Typography } from '@mui/material';

import theme from '../../theme';
import palette from '../../theme/palette';

export const TourPage = styled(Box)({
  color: palette.primary,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
  },
});

export const TourCard = styled(Card)({
  maxWidth: '70%',
  width: '100%',
  background: palette.lightGray,
  marginBottom: '20px',
  paddingBottom: '10px',
  boxSizing: 'border-box',
  [theme.breakpoints.down('xl')]: {
    maxWidth: '68%',
  },
  [theme.breakpoints.down('lg')]: {
    maxWidth: '100%',
    padding: '0 10px 10px',
  },
});

export const CreatorCard = styled(Card)({
  maxWidth: '26%',
  background: palette.lightGray,
  padding: '15px',
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  boxSizing: 'border-box',
  [theme.breakpoints.down('lg')]: {
    maxWidth: '100%',
    width: '100%',
    marginBottom: '20px',
  },
});

export const TourCardTopLine = styled(Box)({
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
});

export const Info = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
  padding: '15px 15px 0 15px',
  [theme.breakpoints.down('md')]: {
    alignItems: 'center',
    '> div:first-of-type': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
});

export const StyledTitle = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '5px',
  textAlign: 'center',
  h4: {
    marginRight: '20px',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'center',
    h4: {
      margin: 0,
      fontSize: '20px',
    },
  },
});

export const AgeRestriction = styled(Box)({
  width: '40px',
  height: '40px',
  borderRadius: '50px',
  border: '3px solid',
  borderColor: palette.green,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 'bold',
  lineHeight: '16px',
  boxSizing: 'border-box',
});

export const Status = styled(Box)({
  width: '215px',
  display: 'flex',
  marginTop: '15px',
  '.MuiFormControl-root': {
    margin: '0 auto',
  },
  button: {
    marginTop: '10px!important',
  },
});

export const Contacts = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '.MuiLink-root': {
    margin: '0 0 10px 0',
  },
  '.MuiChip-root': {
    backgroundColor: palette.secondary,
    color: palette.white,
    cursor: 'pointer',
    padding: '5px',
  },
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
    '.MuiLink-root': {
      margin: '10px 10px 0',
    },
  },
});

export const StyledTable = styled(Table)({
  width: '400px',
  td: {
    fontSize: '14px',
  },
  [theme.breakpoints.down('md')]: {
    width: '280px',
    td: {
      padding: '5px',
    },
  },
});

export const StyledChip = styled(Chip)({
  background: palette.secondary,
  color: palette.white,
  marginBottom: '5px',
  '&:not(:last-of-type)': {
    marginRight: '5px',
  },
  [theme.breakpoints.down('md')]: {
    marginTop: '5px',
  },
});

export const Location = styled(Typography)({
  marginTop: '5px',
});

export const StyledTabs = styled(Box)({});
