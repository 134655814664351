import instance from '../api';

export const GuideService = {
  async getGuides() {
    return instance.get('/admin/guide').then((response) => response.data);
  },
  async getGuide(id) {
    return instance.get(`/admin/guide/${id}`).then((response) => response.data);
  },
};
