import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { AuthService } from '../services/Auth.service';

export const AuthContext = React.createContext();

export function useAuthContext() {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error('useAuthContext must be used within a AuthProvider');
  }
  return authContext;
}

export const AuthContextProvider = ({ children }) => {
  const [isAuth, setIsAuth] = useState(localStorage.getItem('token'));

  useEffect(() => {
    if (localStorage.getItem('token')) {
      setIsAuth(true);
    }
  }, []);

  const signIn = async (email, password) => {
    const response = await AuthService.signIn(email, password);
    localStorage.setItem('token', response?.accessToken);
    localStorage.setItem('refreshToken', response?.refreshToken);
    setIsAuth(true);
  };

  const signOut = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    setIsAuth(false);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuth,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.node,
};
