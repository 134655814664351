export const ROUTES = {
  home: '/',
  user: '/user/:id',
  login: '/login',
  tours: '/tours',
  tour: '/tour/:id',
  guides: '/guides',
  guide: '/guide/:id',
  reviews: '/reviews',
};

export const TYPE_MODAL = {
  TEST: 'test',
};

export const MENU_ITEMS = [
  {
    id: 1,
    name: 'Users',
    path: '/',
  },
  {
    id: 2,
    name: 'Guides',
    path: '/guides',
  },
  {
    id: 3,
    name: 'Tours',
    path: '/tours',
  },
  {
    id: 4,
    name: 'Reviews',
    path: '/reviews',
  },
];
export const API_URL = process.env.REACT_APP_API_HOST;

export const TOUR_STATUSES = [
  {
    id: 1,
    status: 'editing',
    value: 'editing',
  },
  {
    id: 2,
    status: 'waitingModeration',
    value: 'waiting moderation',
  },
  {
    id: 3,
    status: 'declined',
    value: 'declined',
  },
  {
    id: 4,
    status: 'active',
    value: 'active',
  },
  {
    id: 5,
    status: 'deleted',
    value: 'deleted',
  },
  {
    id: 6,
    status: 'moderating',
    value: 'moderating',
  },
];

export const REVIEWS_STATUSES = [
  {
    id: 1,
    status: '',
    title: 'All',
  },
  {
    id: 2,
    status: 'accepted',
    title: 'Accepted',
  },
  {
    id: 3,
    status: 'declined',
    title: 'Declined',
  },
  {
    id: 4,
    status: 'waiting for moderation',
    title: 'Waiting for moderation',
  },
  {
    id: 5,
    status: 'moderating',
    title: 'Moderating',
  },
];

export const SELECT_OPTIONS = [
  {
    value: 'accepted',
    label: 'Accepted',
  },
  {
    value: 'declined',
    label: 'Declined',
  },
  {
    value: 'waiting for moderation',
    label: 'Waiting for moderation',
  },
  {
    value: 'moderating',
    label: 'Moderating',
  },
];
