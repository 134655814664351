import { Box, Typography } from '@mui/material';

import { LoginForm } from '../../components';

export const Login = () => {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      width={'100%'}
    >
      <Typography fontSize={24} marginBottom={'20px'}>
        Login
      </Typography>
      <LoginForm />
    </Box>
  );
};
