import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';

import { useAuthContext } from '../../context';
import { loginValidationSchema } from '../../validation/index';
import { Button, ControlInput } from '../index';
import { StyledForm } from './styles';

export const LoginForm = () => {
  const { signIn } = useAuthContext();

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(loginValidationSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const email = watch('email');
  const password = watch('password');

  const onSubmit = () => {
    signIn(email, password);
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <ControlInput
        control={control}
        placeholder={'Email'}
        name={'email'}
        errors={errors?.email?.message}
      />
      <ControlInput
        control={control}
        placeholder={'Password'}
        type={'password'}
        name={'password'}
        errors={errors?.password?.message}
      />
      <Button className={'primary center'} type='submit'>
        Enter
      </Button>
    </StyledForm>
  );
};
