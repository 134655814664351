import instance from '../api';

export const UserService = {
  async getUsers() {
    return instance.get('/admin/user').then((response) => response.data);
  },
  async getUser(id) {
    return instance.get(`/admin/user/${id}`).then((response) => response.data);
  },
};
