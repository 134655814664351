import { createTheme } from '@mui/material/styles';

import palette from './palette';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 320,
      sm: 480,
      md: 768,
      lg: 960,
      xl: 1200,
    },
  },

  typography: {
    color: palette.primary,
    fontFamily: ['Open Sans, sans-serif'].join(','),
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '16px',
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          height: '100%',
          '& *': {
            margin: 0,
            padding: 0,
            listStyle: 'none',
            outline: 'none',
            boxSizing: 'border-box',
          },

          '& a': {
            color: 'inherit',
            textDecoration: 'none',
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          height: '40px',
          fontSize: 16,
          fontWeight: 500,
          borderRadius: '12px',
          boxShadow: 'none',
        },
      },
    },

    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'rgba(0, 0, 0, 0.1)',
            },

            '&:hover fieldset': {
              borderColor: 'rgba(0, 0, 0, 0.1)',
            },

            '&.Mui-focused fieldset': {
              borderColor: '#19d263',
            },
          },
        },
      },
    },
  },
});

export default theme;
