import { Logout } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
// eslint-disable-next-line object-curly-newline
import { Box, IconButton, Menu, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import logo from '../../assets/img/logo.svg';
import { MENU_ITEMS, ROUTES } from '../../constants';
import { useAuthContext } from '../../context';
import { Container, StyledMenu, StyledNavLink } from './styles';

export const Layout = ({ children }) => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const { isAuth, signOut } = useAuthContext();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const activeClassMenuItem = ({ isActive }) => (isActive ? 'active' : '');

  const desktopMenu = () => (
    <StyledMenu
      sx={{
        display: {
          xs: 'none',
          md: 'flex',
        },
      }}
    >
      {MENU_ITEMS.map(({ id, name, path }) => (
        <StyledNavLink to={path} key={`${id}-${name}`} activeClassName={activeClassMenuItem} end>
          {name}
        </StyledNavLink>
      ))}
    </StyledMenu>
  );

  const mobileMenu = () => (
    <StyledMenu
      sx={{
        display: {
          xs: 'flex',
          md: 'none',
        },
      }}
    >
      <IconButton
        size='large'
        aria-label='account of current user'
        aria-controls='menu-appbar'
        aria-haspopup='true'
        onClick={handleOpenNavMenu}
        color='inherit'
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id='menu-appbar'
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: {
            xs: 'block',
            md: 'none',
          },
        }}
      >
        {MENU_ITEMS.map(({ id, name, path }) => (
          <MenuItem key={id} onClick={handleCloseNavMenu}>
            <StyledNavLink to={path} activeClassName={activeClassMenuItem} end>
              {name}
            </StyledNavLink>
          </MenuItem>
        ))}
      </Menu>
    </StyledMenu>
  );

  return (
    <Box display={'flex'} flexDirection={'column'} minHeight={'100vh'}>
      <Box
        margin={'15px 30px 30px'}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Link to={ROUTES.home}>
          <img src={logo} alt={'Umeguide'} />
        </Link>
        {isAuth && (
          <Box display={'flex'} alignItems={'center'}>
            {mobile ? mobileMenu() : desktopMenu()}
            <IconButton
              onClick={signOut}
              sx={{
                mt: '3px',
              }}
            >
              <Logout />
            </IconButton>
          </Box>
        )}
      </Box>
      <Container>{children}</Container>
    </Box>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};
